import React, { Component } from "react";
import { Col, Row, Button , Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import $ from 'jquery';
import Alert from '../../Alert';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false
    };

    this.handleData = this.handleData.bind(this);
    this.validateData = this.validateData.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)
}

  validateData(callback) {
      let validateBool = false;

      //Values saved
      let userid = $('#userid').val();
      let name = $('#name').val();
      let birthday = $('#birthday').val();
      let first_travel = $("#first_travel").val();
      let phone = $('#phone').val();


      if(name !== "" && birthday !== "" && first_travel !== "" && userid !== "") {
        validateBool = true;
      }

       //Verification of everything
      if(validateBool){

          let newTravel =  {
              "userid": userid,
              "name": name,
              "birthday": birthday,
              "first_travel": first_travel,
              "travels_counter": 0,
              "phone": phone.length > 5 ? phone : ""

          };

        this.setState({isValid: true}, ()=> callback(newTravel));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  handleData (e) {
      this.toggleLoading();
      this.validateData((data)=>{
          if (this.state.isValid) {
                let value;
                value = this.props.set_fieldDefault("users",data,"Usuario");
                console.log(value)

          } else {
              Alert.errorAlert("¡Falta informacion! Revise y vuelta a intentar");
              this.toggleLoading();
            }
      });

      e.preventDefault(); //Don't execute
    }

  renderChangeOption() {
      this.props.changeOption(1, null);
    }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  render() {
    return (
      <div className="">
        <Form>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="userid">ID de viajero *</Label>
                <Input type="text" placeholder="VA-Usuario-######" id="userid" required/>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
          <Col md={6}>
              <FormGroup>
                <Label for="name">Nombre *</Label>
                <Input type="text" name="name" id="name" required />
              </FormGroup>
          </Col>

          <Col md={6}>
              <FormGroup>
              <Label for="birthday">Cumpleaños *</Label>
                <Input type="text" name="birthday" id="birthday" placeholder="dd/mm" required />
              </FormGroup>
          </Col>
          </Row>


          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Teléfono</Label>
                <Input type="text" name="phone" id="phone"/>
              </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                  <Label for="first_travel">Fecha de primer viaje *</Label>
                  <Input type="date" name="first_travel" id="first_travel" required />
                </FormGroup>
            </Col>
          </Row>

            <div className=" buttons-panel">
              <button onClick={(e) => this.handleData(e)}>Crear usuario</button>
              <Button onClick={() => this.renderChangeOption()}>Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
            </div>
          </Form>

      </div>
    );
  }
}

export default AddUser;
