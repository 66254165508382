import React, { Component } from "react";

class ItemOrder extends Component {

  render() {
    let id = this.props.id;
    let travelid = this.props.travelid;
    let destination = this.props.destination;
    let seats = this.props.seats;
    let total = this.props.total;
    let paid = this.props.paid;

    let remaining = total - paid;

    return (
          <tr>
            <th scope="row" className="maxTh">{travelid}</th>
            <td>{destination}</td>
            <td>{seats}</td>
            <td>$ {total}</td>
            <td>$ {paid}</td>
            <td>$ {remaining}</td>
            <td>
              <i className="options" onClick={() => this.props.changeOption(3, id)}><i className="pIcon fas fa-edit"></i></i>
              <i className="options" onClick={() => this.props.renderDelete(id,`el registro para ${travelid}`)}><i className="pIcon delete fas fa-trash-alt"></i></i>

            </td>
          </tr>
    );
  }
}

export default ItemOrder;
