import React, { Component } from "react";
import { Button, Table } from 'reactstrap';

class General extends Component {

  componentDidMount() {
  window.scrollTo(0, 0)
}

  render() {

    return (
      <div className="container panelC">
            <h2>Bienvenido Admin</h2>

            <div className="actions-space">
              <Button color="success">Editar información</Button>
            </div>

              <Table hover>
              <thead className="thead">
                <tr>
                  <th>Correo</th>
                  <th>Viajes pendientes</th>
                  <th>Viajes terminados</th>
                  <th>Total</th>
                </tr>
              </thead>

              </Table>


        </div>
    );
  }
}

export default General;
