import React, { Component } from "react";
import { Col, Row, Button , Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import $ from 'jquery';
import Alert from '../../Alert';

class EditTravel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        travel: null,
        isValid: false
    };

    this.handleData = this.handleData.bind(this);
    this.validateData = this.validateData.bind(this);
    this.getTravel = this.getTravel.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)

    this.getTravel((travel) => {
        $("#travelid").val(travel.travelid);
        $("#destination").val(travel.destination);
        $("#date").val(travel.date);
        $("#cost").val(travel.cost);
        $("#capacity").val(travel.capacity);
        $("#information").val(travel.information);

        this.setState({
          travel: travel
        });

    });
}

  getTravel(callback) {
    //Send the user information to the backend
    this.props.get_item("travels",this.props.travelid)
    .then(function(data) {
      callback(data);
    })
    .catch(function () {
      callback([]);
    });

  }

  validateData(callback) {
      let validateBool = false;

      //Values saved
      let date = $('#date').val();
      let cost = $("#cost").val();
      let capacity = $('#capacity').val();
      let information = $('#information').val();


      if(date !== "" && cost !== "" && capacity !== "") {
        validateBool = true;
      }

       //Verification of everything
      if(validateBool){

          let newTravel =  {
              "date": date,
              "cost": cost,
              "capacity": capacity,
              "information": information
          };

        this.setState({isValid: true}, ()=> callback(newTravel));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  handleData (e) {
      this.toggleLoading();
      this.validateData((data)=>{
          if (this.state.isValid) {
                this.props.update_field("travels",this.props.travelid, data,"Viaje fue modificado");

          } else {
              Alert.errorAlert("¡Falta informacion! Revise y vuelta a intentar");
              this.toggleLoading();
            }
      });

      e.preventDefault(); //Don't execute
    }

  renderChangeOption() {
      this.props.changeOption(1, null);
  }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  render() {
    return (
      <div className="">
        <Form>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="travelid">ID de viaje</Label>
                <Input type="text" placeholder="VA-Destino-######" id="travelid" readOnly/>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
          <Col md={6}>
              <FormGroup>
                <Label for="destination">Destino</Label>
                <Input type="text" name="destination" id="destination" readOnly />
              </FormGroup>
          </Col>

          <Col md={6}>
              <FormGroup>
              <Label for="date">Fecha</Label>
                <Input type="date" name="date" id="date" min="2020-01-01" max="2050-12-31" required />
              </FormGroup>
          </Col>
          </Row>


          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="cost">Costo</Label>
                <Input type="number" name="cost" id="cost"  required/>
              </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                  <Label for="capacity">Cantidad de asientos</Label>
                  <Input type="number" name="capacity" id="capacity" required />
                </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="information">Información</Label>
                <Input type="textarea" name="information" id="information"  required/>
              </FormGroup>
            </Col>
          </Row>

            <div className=" buttons-panel">
              <button onClick={(e) => this.handleData(e)}>Modificar viaje</button>
              <Button onClick={() => this.renderChangeOption()}>Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
            </div>
          </Form>

      </div>
    );
  }
}

export default EditTravel;
