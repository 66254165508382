import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap//dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp({
  apiKey: "AIzaSyCIRn8j1hOCDcLki8KA27eHUR7B-5VgbPA",
  authDomain: "viaventuras-31f95.firebaseapp.com",
  databaseURL: "https://viaventuras-31f95.firebaseio.com",
  projectId: "viaventuras-31f95",
  storageBucket: "viaventuras-31f95.appspot.com",
  messagingSenderId: "864900208013",
  appId: "1:864900208013:web:700eedfef94fdd287fd9c4",
  measurementId: "G-9MWV0RRW7J"
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
