import React, { Component } from "react";
import $ from 'jquery';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    };


  }

  componentDidMount() {
  window.scrollTo(0, 0)
  $("header").removeClass("lastHeader");

    setTimeout(() => {
      $("#about").toggleClass("none zoomIn animated");
    }, 1000); // simulates an async action, and hides the spinner

    setTimeout(() => {
      $("#contentGeneral").toggleClass("none fadeInUp animated");
    }, 2000); // simulates an async action, and hides the spinner
}


  render() {
    return (
      <div>
          <div className="wallpaperAbout">
            <div className="textAbout">
              <h3 id="about" className="none">Nosotros</h3>
            </div>
          </div>

          <div className="container">
            <div className= "card card-body">
              <center><h2 className="bold">Acerca de Viaventuras</h2></center>
              <p>
                Somos una agencia que inicia en el año 2020, en la ciudad de Guadalajara, Jal. con la finalidad de ofrecer viajes a diferentes partes del país motivando a que las personas se <i className="bold">viaventuren </i>
                a viajar,<i className="slogan"> porque viajar vale la pena</i>. Brindando la mejor atención, servicio y precio para nuestros clientes.
              </p>
              <br/>
              <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 mt-0">Misión</h4>
                      </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8">
                              <p>Superar las expectativas de los <i className="bold"> Viaventureros</i> ofreciéndoles precios justos y un servicio de <i className="bold"> excelente calidad</i>.</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 mt-0">Visión</h4>
                      </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8">
                              <p>Posicionarnos del mercado como una empresa lider en la realicación de viajes, mediante una excelente atención, servicio y rapidez de parte nuestra.</p>
                            </div>
                          </div>
                      </div>
                    </div>
                    <br/>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 mt-0">Valores</h4>
                      </div>
                        <div className="card-body">
                          <ul className="ul-icons list-unstyled">
                            <li>
                              <p><i className="fa fa-check"></i> Honestidad.</p>
                            </li>
                            <li>
                              <p><i className="fa fa-check"></i> Responsabilidad.</p>
                            </li>
                            <li>
                              <p><i className="fa fa-check"></i> Respeto.</p>
                            </li>
                          </ul>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
export default About;
