import React, { Component } from "react";
import { Button,Table } from 'reactstrap';
import ItemTravel from './ItemTravel';
import AddTravel from './AddTravel';
import EditTravel from './EditTravel';

import Alert from '../../Alert';

class TravelsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1,
      travels: [],
      selectedTravel: null,
    };

    this.getTravels = this.getTravels.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.getTravels((travels) => {
      this.setState({
        travels: travels
      })
    });
  }


  getTravels(callback) {
      this.props.get_collection("travels")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }


  renderDelete (key, name) {
    Alert.warningAlert(name).then((willDelete) => {
      if(willDelete)
      {
          this.props.delete_doc("travels",key, name);
      } else {
        Alert.actionCanceled();
      }
    })
  }

  changeOption(option, id) {
      this.setState({
        option: option,
        selectedTravel: id
      })
  }


  renderOptions()  {

    switch (this.state.option) {
      case 1:

          let travels;

          this.state.travels.length > 0 ?
            travels = this.state.travels
              .map(travel => {
                return (
                  <ItemTravel
                    key={travel.id}
                    id={travel.id}
                    travelid={travel.data.travelid}
                    destination={travel.data.destination}
                    date={travel.data.date}
                    cost={travel.data.cost}
                    capacity={travel.data.capacity}
                    occupied={travel.data.occupied}
                    changeOption={this.changeOption}
                    renderDelete ={this.renderDelete}
                  />
                );
              })
          : travels = <p>No hay ningún viaje</p>;

        return (
            <div>

                <Table hover>
                <thead className="thead">
                  <tr>
                    <th>ID</th>
                    <th>Destino</th>
                    <th>Fecha</th>
                    <th>Costo</th>
                    <th>Asientos</th>
                    <th>Libres</th>
                    <th>Acciones</th>
                  </tr>
                </thead>

                <tbody>{travels}</tbody>

                </Table>
            </div>
        )
        break;

      case 2:
        return (
          <div>
          <AddTravel
          changeOption={this.changeOption}
          set_fieldDefault = {this.props.set_fieldDefault}
          />
          </div>
        )
        break;

      case 3:
        return (
          <div>
          <EditTravel
          travelid = {this.state.selectedTravel}
          changeOption={this.changeOption}
          get_item = {this.props.get_item}
          update_field = {this.props.update_field}
          />
          </div>
        )
        break;

      default:
        return (
          <div>
            Ninguna opción fue seleccionada.
          </div>
        )
        break;
    }

  }


  render() {
    let textButton = this.state.option === 1 ? "Nuevo viaje" : "Regresar";
    let textTitle = this.state.option === 1 ? "Administrar viajes" : this.state.option === 2 ? "Nuevo destino" : "Modificar destino";

    return (
      <div className="container panelC">
            <h2>{textTitle}</h2>

            <div className="actions-space buttons-panel" >
              {  this.state.option === 1 ?
                <section>
                <Button color="success" onClick={() => {this.changeOption(2,null)}}>{textButton}</Button>
                <Button color="success" onClick={() => {this.changeOption()}}>Relación de viaje</Button>
                </section>
                : <Button color="success" onClick={() => {this.changeOption(1,null)}}>{textButton}</Button>
              }
            </div>

            {this.renderOptions()}

        </div>
    );
  }
}

export default TravelsPanel;
