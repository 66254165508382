import React, { Component } from "react";
import Alert from '../../Alert';

class ItemTestimonial extends Component {

  showMessage(message) {
    Alert.infoAlert(message);
  }

  renderValidate (id, name) {
    return <i onClick={() => this.props.validateTestimonial(id, name)}><i className="pIcon far fa-check-circle"></i></i>;
  }

  render() {
    let id = this.props.id;
    let name = this.props.name;
    let rate = this.props.rate;
    let message = this.props.message;
    let email = this.props.email;
    let valid = this.props.valid;

    let isvalid = valid === true  ? "Válido" : "No válido";

    return (
          <tr>
            <th scope="row">{name}</th>
            <td>{rate}</td>
            <td>{email}</td>
            <td>{isvalid}</td>
            <td>
              <i className="options" onClick={() => this.showMessage(message)}><i className="pIcon far fa-comment"></i></i>
              <i className="options" onClick={() => this.props.renderDelete(id, name)}><i className="pIcon delete fas fa-trash-alt"></i></i>
              {valid === false ? this.renderValidate(id, name) : null }

            </td>
          </tr>
    );
  }
}

export default ItemTestimonial;
