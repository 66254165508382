import React, { Component } from "react";
import { Col, Row, Button , Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import $ from 'jquery';
import Alert from '../../Alert';

class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false
    };

    this.handleData = this.handleData.bind(this);
    this.validateData = this.validateData.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)
}

  validateData(callback) {
      let validateBool = false;

      //Values saved
      let title = $('#title').val();
      let content = $('#content').val();
      let category = $("#category").val();


      if(title !== "" && content !== "" && category !== "X") {
        validateBool = true;
      }

       //Verification of everything
      if(validateBool){

          let cat;
          let idCat;
          if(category === "0")
          {
            idCat = "Reservaciones";
            cat = 0;
          } else if (category === "1") {
            idCat = "Pagos y Reembolsos";
            cat = 1;
          } else if (category === "2") {
            idCat = "Cancelaciones";
            cat = 2;
          }


          let newQuestion =  {
              "title": title,
              "content": content,
              "category": cat,
              "categoryText": idCat
          };

        this.setState({isValid: true}, ()=> callback(newQuestion));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  handleData (e) {
      this.toggleLoading();
      this.validateData((data)=>{
          if (this.state.isValid) {
                let value;
                value = this.props.set_fieldDefault("faqs",data,"Pregunta");
                console.log(value)

          } else {
              Alert.errorAlert("¡Falta informacion! Revise y vuelta a intentar");
              this.toggleLoading();
            }
      });

      e.preventDefault(); //Don't execute
    }

  renderChangeOption() {
      this.props.changeOption(1);
    }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  render() {
    return (
      <div className="">
        <Form>
          <Row form>
          <Col>
              <FormGroup>
                <Label for="title">Pregunta *</Label>
                <Input type="text" name="title" id="title" required />
              </FormGroup>
          </Col>
          </Row>

          <Row form>
          <Col>
              <FormGroup>
              <Label for="content">Respuesta *</Label>
                <Input type="textarea" name="content" id="content" required />
              </FormGroup>
          </Col>
          </Row>

          <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="category">Categoría *</Label>
              <Input type="select" name="category" id="category" required>
                <option value="X">Seleccionar...</option>
                <option value="0">Reservaciones</option>
                <option value="1">Pagos y Reembolsos</option>
                <option value="2">Cancelaciones</option>
              </Input>
            </FormGroup>
          </Col>
          </Row>

            <div className=" buttons-panel">
              <button onClick={(e) => this.handleData(e)}>Agregar pregunta</button>
              <Button onClick={() => this.renderChangeOption()}>Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
            </div>
          </Form>

      </div>
    );
  }
}

export default AddQuestion;
