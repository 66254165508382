import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import $ from 'jquery';

class Header extends Component{
    constructor(props){
        super(props);

        this.state = {
            isNavOpen: false,
            yPosition: 0,
            collapse: true,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        // Click and hide menumobile .lnk-menu
        $(".iMobile").click(function() {
            $( "#menu" ).toggleClass("targetMobileMenu");
        });

        $(".lnk-menu").click(function() {
            $( "#menu" ).toggleClass("targetMobileMenu");
        });

    }

    handleScroll() {
        let itemTranslate = window.pageYOffset;

        this.setState({
            yPosition: itemTranslate
        });

        if(this.state.yPosition > 10){
          // > no px
          $("header").addClass("lastHeader");
          }
          else{
            if (window.location.href.includes("ingresar") === false && window.location.href.includes("informacion") === false && window.location.href.includes("terminos-y-condiciones") === false) {
              $("header").removeClass("lastHeader");
            }
          }

    }


    render(){

      return(

          <header className="">

          <div id="controlHeader" className="container initialHeader">
            <div className="brand fadeInLeftBig animated">
              <h3>Viaventuras</h3>
            </div>

            <div className="alignMenu">
              <nav className="dropdownmenu">
                <ul>
                  <li>
                    <NavLink to={`/inicio`}>
                    Inicio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/nosotros`}>
                    Nosotros
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/viajes`}>
                    Viajes
                    </NavLink>
                    </li>
                  <li>
                  <NavLink to={`/opiniones`}>
                  Opiniones
                  </NavLink></li>
                  <li>
                  <NavLink to={`/contacto`}>
                  Contacto
                  </NavLink></li>
                </ul>
              </nav>
            </div>

          </div>


          <div className="container desap">
            <i className="toggle-menu"><i className="fa fa-bars iMobile"></i></i>
            <nav id="menu">
              <i className="toggle-menu"><i className="fa fa-times iMobile"></i></i>
                <ul>
                  <li>
                  <NavLink to={`/inicio`} className="lnk-menu">
                  Inicio
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to={`/nosotros`} className="lnk-menu">
                  Nosotros
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to={`/viajes`} className="lnk-menu">
                  Viajes
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to={`/opiniones`} className="lnk-menu">
                  Opiniones
                  </NavLink></li>
                  <li>
                  <NavLink to={`/informacion`} className="lnk-menu">
                  ¿Cómo Reservar?
                  </NavLink></li>
                  <li>
                  <NavLink to={`/preguntas`} className="lnk-menu">
                  Preguntas Frecuentes
                  </NavLink></li>
                  <li>
                  <NavLink to={`/contacto`} className="lnk-menu">
                  Contacto
                  </NavLink></li>
                </ul>
            </nav>
          </div>

          </header>
        );
    }

}

export default withRouter(Header);
