import swal from 'sweetalert';

let Alert  = {

  successAlert: (message) => {
        return (
          swal({
            icon: "success",
            title: "¡Bien!",
            text: message,
            button: "OK",
          })
          .then((value) => {
            return value
          })
      )

  },

  warningAlert: (name) => {
    return (
        swal({
          title: "¿Estás seguro?",
          text: "Una vez elimines " + name + ", no podrás recuperar la información!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willAction) => {
          return willAction
        })
    )
  },

  resetAlert: () => {
    return (
        swal({
          title: "¿Estás seguro?",
          text: "Once you reset orders counter, you must delete orders are not needed!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willAction) => {
          return willAction
        })
    )
  },

  inputAlert: (name) => {
    return (
      swal("Cuanto descuento quieres agregar a " + name, {
        content: "input",
      })
      .then((value) => {
        return value;
      })
    )
  },

  confirmAction: (message) => {
    return swal(message, {
      buttons: {
        cancel: "Cancelar",
        validate: "Validar",
      },
    })
    .then((value) => {
      return value
    })
  },

  //Actions Completed
  deletedAlert: (name) => {
      return swal(name + " ha sido eliminado!", {
        icon: "success",
      })
      .then((value) => {
        return value
      });
  },


  actionSuccess: (message) => {
      return swal(message, {
        icon: "success",
      })
      .then((value) => {
        return value
      });
  },

  actionCanceled: () => {
    return swal("Acción cancelada");
  },

  infoAlert: (message) => {
    return swal(`Información:\n\n${message}`);
  },

  errorAlert: (message) => {
    return swal({
      text: message,
      icon: "error",
    });
  }


}



export default Alert;
