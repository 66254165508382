import React, { Component , Fragment } from "react";
import Question from './Question';

class SectionQuestions extends Component {

  render() {
    let title = this.props.title;
    let questions;

    questions = this.props.questionsList
      .map(qt => {
        return (
          <Question
            key = {qt.id}
            question={qt.data.title}
            information={qt.data.content}
          />
        );
      });


    // Empty and Loading States
    let view;
    if (questions.length <= 0) {
      //view = <NoResults />;
    } else {
      view = (
        <Fragment>
          <h3>{title}</h3>
          {questions}
        </Fragment>
      );
    }


    return <div >{view}</div>;
  }
}

export default SectionQuestions;
