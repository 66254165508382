import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Panel from './Panel';
import General from './General';
import TravelsPanel from './Travels/TravelsPanel';
import TestimonialsPanel from './Testimonials/TestimonialsPanel';
import QuestionsPanel from './Questions/QuestionsPanel';
import UsersPanel from './Users/UsersPanel';
import OrderPanel from './Orders/OrderPanel';

import Alert from '../Alert';

import firebase from 'firebase';
var database;

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1
    };

  }

  componentDidMount() {
  window.scrollTo(0, 0);
}


  componentWillMount() {
      // FIREBASE references
      database = firebase.firestore();
  }

    /* START DB FUNCTIONS */
    get_collection (collection) {
      var db_ref = database.collection(collection);
        return db_ref.get()
        .then(function (snapshot) {
          let data = [];
            snapshot.forEach((doc) => {
              data.push({
                id: doc.id,
                data: doc.data()
              });
            });
            return data;
          })
        .catch(function(error) {
          return null
        });
    }

    get_item (collection, id) {
      var db_ref = database.collection(collection);
        return db_ref.doc(id).get()
        .then(function (snapshot) {
            return snapshot.data();
          })
        .catch(function(error) {
          return null
        });
    }

    set_field (collection, id, data, message) {
        var setDoc = database.collection(collection).doc(id);
        setDoc.set(data)
        .then(function() {
            Alert.successAlert(message).then((value) => {
              window.location.reload();
            });
        })
        .catch(function(error) {
            Alert.errorAlert("Algo salió mal, por favor verifica que tienes conexión a internet y vuelve a intentarlo!").then((value) => {

            });
        });
    }

    update_field (collection, id, data, message) {
        var setDoc = database.collection(collection).doc(id);
        setDoc.set(data, { merge: true })
        .then(function() {
            Alert.successAlert(message).then((value) => {
              window.location.reload();
            });
        })
        .catch(function(error) {
            Alert.errorAlert("Algo salió mal, por favor verifica que tienes conexión a internet y vuelve a intentarlo!").then((value) => {

            });
        });
    }

    set_fieldDefault (collection, data, type) {
        var setDoc = database.collection(collection);
        return setDoc.add(data)
        .then(function() {
            Alert.successAlert(type +" agregado!").then((value) => {
              //window.location.reload();
            });
        })
        .catch(function(error) {
            Alert.errorAlert("Algo salió mal, por favor verifica que tienes conexión a internet y vuelve a intentarlo!").then((value) => {

            });
        });
    }

    delete_doc (collection, id, name) {
        var deleteDoc = database.collection(collection).doc(id);
        deleteDoc.delete()
        .then(function() {
            Alert.deletedAlert(name).then((value) => {
              window.location.reload();
            });
        })
        .catch(function(error) {
            Alert.errorAlert("Algo salió mal, por favor verifica que tienes conexión a internet y vuelve a intentarlo!").then((value) => {

            });
        });
    }

    /* END DB FUNCNTIONS */



    render() {
       return (
         <div>
             <Panel
             onLogout={this.props.onLogout}
             />
             <Switch>
                 <Route exact path="/" render={(props) =>
                   <General
                      get_collection = {this.get_collection}
                      set_field = {this.set_field}
                      update_field = {this.update_field}
                     {...props} />
                 } />
                 <Route exact path="/admin" render={(props) =>
                   <General
                      get_collection = {this.get_collection}
                      set_field = {this.set_field}
                      update_field = {this.update_field}
                     {...props} />
                 } />
                 <Route exact path="/viajes" render={(props) =>
                   <TravelsPanel
                      get_collection = {this.get_collection}
                      get_item = {this.get_item}
                      set_fieldDefault = {this.set_fieldDefault}
                      delete_doc = {this.delete_doc}
                      update_field = {this.update_field}
                     {...props} />
                 } />
                 <Route exact path="/opiniones" render={(props) =>
                   <TestimonialsPanel
                      get_collection = {this.get_collection}
                      delete_doc = {this.delete_doc}
                      update_field = {this.update_field}
                     {...props} />
                 } />
                 <Route exact path="/preguntas" render={(props) =>
                   <QuestionsPanel
                      get_collection = {this.get_collection}
                      set_fieldDefault =  {this.set_fieldDefault}
                      delete_doc = {this.delete_doc}
                     {...props} />
                 } />
                 <Route exact path="/viajeros" render={(props) =>
                   <UsersPanel
                      get_collection = {this.get_collection}
                      get_item = {this.get_item}
                      set_fieldDefault =  {this.set_fieldDefault}
                      delete_doc = {this.delete_doc}
                      update_field = {this.update_field}
                     {...props} />
                 } />
                 <Route exact path="/ordenes" render={(props) =>
                   <OrderPanel
                      get_collection = {this.get_collection}
                      get_item = {this.get_item}
                      set_fieldDefault =  {this.set_fieldDefault}
                      delete_doc = {this.delete_doc}
                      update_field = {this.update_field}
                     {...props} />
                 } />
             </Switch>

             <br/>
         </div>
       )
    }
}

export default MainPanel;
