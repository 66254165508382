import React, { Component } from "react";
import { Button,Table } from 'reactstrap';
import ItemOrder from './ItemOrder';
import AddOrder from './AddOrder';

import Alert from '../../Alert';

class OrderPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1,
      orders: [],
      selectedOrder: null,
    };

    this.getOrders = this.getOrders.bind(this);
    this.getCollection = this.getCollection.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.getOrders((orders) => {
      this.setState({
        orders: orders
      })
    });
  }


  getOrders(callback) {
      this.props.get_collection("orders")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }

  getCollection(callback, collection) {
      this.props.get_collection(collection)
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }

  renderDelete (key, name) {
    Alert.warningAlert(name).then((willDelete) => {
      if(willDelete)
      {
          this.props.delete_doc("orders",key, name);
      } else {
        Alert.actionCanceled();
      }
    })
  }

  changeOption(option, id) {
      this.setState({
        option: option,
        selectedOrder: id
      })
  }


  renderOptions()  {

    switch (this.state.option) {
      case 1:

          let orders;

          this.state.orders.length > 0 ?
            orders = this.state.orders
              .map(order => {
                return (
                  <ItemOrder
                    key={order.id}
                    id={order.id}
                    travelid={order.data.travelid}
                    destination={order.data.destination}
                    seats={order.data.seats}
                    total={order.data.total}
                    paid={order.data.paid}
                    changeOption={this.changeOption}
                    renderDelete ={this.renderDelete}
                  />
                );
              })
          : orders = <p>No hay ninguna orden</p>;

        return (
            <div>

                <Table hover>
                <thead className="thead">
                  <tr>
                    <th>Viaje</th>
                    <th>Destino</th>
                    <th>Asientos</th>
                    <th>Costo</th>
                    <th>Pagado</th>
                    <th>Restante</th>
                    <th>Acciones</th>
                  </tr>
                </thead>

                <tbody>{orders}</tbody>

                </Table>
            </div>
        )
        break;

      case 2:
        return (
          <div>
          <AddOrder
          changeOption={this.changeOption}
          getCollection = {this.getCollection}
          set_fieldDefault = {this.props.set_fieldDefault}
          />
          </div>
        )
        break;

      case 3:
        return (
          <div>
          Working on
          </div>
        )
        break;

      default:
        return (
          <div>
            Ninguna opción fue seleccionada.
          </div>
        )
        break;
    }

  }


  render() {
    let textButton = this.state.option === 1 ? "Nueva orden" : "Regresar";
    let textTitle = this.state.option === 1 ? "Administrar órdenes" : "Nueva orden de viaje";

    return (
      <div className="container panelC">
            <h2>{textTitle}</h2>

            <div className="actions-space buttons-panel" >
              {  this.state.option === 1 ?
                <section>
                <Button color="success" onClick={() => {this.changeOption(2,null)}}>{textButton}</Button>
                </section>
                : <Button color="success" onClick={() => {this.changeOption(1,null)}}>{textButton}</Button>
              }
            </div>

            {this.renderOptions()}

        </div>
    );
  }
}

export default OrderPanel;
