import React from 'react';
import { CardBody} from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';

function TestimonialComponent(props) {

    return(
      <div className="card cardTestimonial">
          <CardBody>
            <center>
              {props.testimonial}
              <br/>
              <StarRatingComponent className="rating"
                name="rate"
                editing={false}
                starCount={5}
                value={props.rate}
              />
              <br/>
              <div className="">"{props.name}"</div>
            </center>
          </CardBody>
      </div>
    )
}

export default TestimonialComponent;
