import React, { Component } from "react";
import { Col, Row, Button , Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import $ from 'jquery';
import Alert from '../../Alert';

class AddTravel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false
    };

    this.handleData = this.handleData.bind(this);
    this.validateData = this.validateData.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)
}

  validateData(callback) {
      let validateBool = false;

      //Values saved
      let destination = $('#destination').val();
      let information = $('#information').val();
      let date = $('#date').val();
      let cost = $("#cost").val();
      let capacity = $('#capacity').val();
      let travelid = $('#travelid').val();


      if(destination !== "" && date !== "" && cost !== "" && capacity !== "" && travelid !== "") {
        validateBool = true;
      }

       //Verification of everything
      if(validateBool){

          let newTravel =  {
              "travelid": travelid,
              "destination": destination,
              "date": date,
              "cost": cost,
              "capacity": capacity,
              "occupied": 0,
              "orderslist": [],
              "information": information,
              "birthday_discount": 0

          };

        this.setState({isValid: true}, ()=> callback(newTravel));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  handleData (e) {
      this.toggleLoading();
      this.validateData((data)=>{
          if (this.state.isValid) {
                let value;
                value = this.props.set_fieldDefault("travels",data,"Viaje");
                console.log(value)

          } else {
              Alert.errorAlert("¡Falta informacion! Revise y vuelta a intentar");
              this.toggleLoading();
            }
      });

      e.preventDefault(); //Don't execute
    }

  renderChangeOption() {
      this.props.changeOption(1, null);
    }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  render() {
    return (
      <div className="">
        <Form>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="travelid">ID de viaje *</Label>
                <Input type="text" placeholder="Destino-####" id="travelid" required/>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
          <Col md={6}>
              <FormGroup>
                <Label for="destination">Destino *</Label>
                <Input type="text" name="destination" id="destination" required />
              </FormGroup>
          </Col>

          <Col md={6}>
              <FormGroup>
              <Label for="date">Fecha *</Label>
                <Input type="date" name="date" id="date" min="2020-01-01" max="2050-12-31" required />
              </FormGroup>
          </Col>
          </Row>


          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="cost">Costo *</Label>
                <Input type="number" name="cost" id="cost"  required/>
              </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                  <Label for="capacity">Cantidad de asientos *</Label>
                  <Input type="number" name="capacity" id="capacity" required />
                </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="information">Información *</Label>
                <Input type="textarea" name="information" id="information"  required/>
              </FormGroup>
            </Col>
          </Row>

            <div className=" buttons-panel">
              <button onClick={(e) => this.handleData(e)}>Crear viaje</button>
              <Button onClick={() => this.renderChangeOption()}>Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
            </div>
          </Form>

      </div>
    );
  }
}

export default AddTravel;
