import React, { Component } from "react";
import { Button, Table } from 'reactstrap';
import ItemQuestion from './ItemQuestion';
import AddQuestion from './AddQuestion';

import Alert from '../../Alert';

class QuestionsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1,
      faqs: []
    };

    this.getFaqs = this.getFaqs.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.renderDeleteQuestion = this.renderDeleteQuestion.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)

  this.getFaqs((faqs) => {
    this.setState({
      faqs: faqs
    })
  });
}

  getFaqs(callback) {
      this.props.get_collection("faqs")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }

  renderDeleteQuestion (key, name) {
    Alert.warningAlert(name).then((willDelete) => {
      if(willDelete)
      {
          this.props.delete_doc("faqs",key, name);
      } else {
        Alert.actionCanceled();
      }
    })
  }

  changeOption() {
    let op = this.state.option === 1 ? 2 : this.state.option === 2 ? 1 : 2;
      this.setState({
        option: op
      })
  }


    renderOptions()
    {
        switch (this.state.option) {
          case 1:

              let questions;

              this.state.faqs.length > 0 ?
                questions = this.state.faqs
                  .map(question => {
                    return (
                      <ItemQuestion
                        key={question.id}
                        id={question.id}
                        title={question.data.title}
                        content={question.data.content}
                        category={question.data.categoryText}
                        renderDeleteQuestion ={this.renderDeleteQuestion}
                      />
                    );
                  })
              : questions = <p>No hay ninguna pregunta frecuente</p>;

            return (
                <div>

                    <Table hover>
                    <thead className="thead">
                      <tr>
                        <th>Pregunta</th>
                        <th>Categoría</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>

                    <tbody>{questions}</tbody>

                    </Table>
                </div>
            )
            break;

          case 2:
            return (
              <div>
              <AddQuestion
              changeOption={this.changeOption}
              set_fieldDefault = {this.props.set_fieldDefault}
              />
              </div>
            )
            break;

          default:
            return (
              <div>
                Ninguna opción fue seleccionada.
              </div>
            )
            break;
        }

    }


  render() {
    let textButton = this.state.option === 1 ? "Agregar" : "Regresar";
    let textTitle = this.state.option === 1 ? "Preguntas frecuentes" : "Agregar pregunta";

    return (
      <div className="container panelC">
            <h2>{textTitle}</h2>

            <div className="actions-space">
              <Button color="success" onClick={() => {this.changeOption()}}>{textButton}</Button>
            </div>

            {this.renderOptions()}

        </div>
    );
  }
}

export default QuestionsPanel;
