import React from 'react';
import $ from 'jquery';

function Information(props) {

  (function init() {
    window.scrollTo(0, 0)
    $("header").addClass("lastHeader");
  })();

  return(
    <div>
    <br/><br/>
        <div className="container">
          <div className= "card card-body">
            <center><h2 className="bold">¿Cómo Reservar?</h2></center>
            <br/>
            <div className="row">
              <div className="col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-0 mt-0 uppercase"><i className="fa fa-volume-control-phone"></i> paso 1</h4>
                    </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8">
                            <p>Comunícate con nosotros para consultar disponibilidad de asitentos. Puede ser via telefónica, facebook o <a className="bold" href="https://wa.me/5213151013740?text=Me%20gustar%C3%ADa%20consultar%20la%20disponibilidad%20de%20un%20viaje." target="_blank" rel="noopener noreferrer"> WhatsApp</a>.</p>
                          </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-0 mt-0 uppercase"><i className="fa fa-usd"></i> paso 2</h4>
                    </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8">
                            <p>Haz tu depósito/transferencia a nuestra cuenta bancaria, envíanos tu ficha, nombre completo y fecha de cumpleaños (día y mes) de los viajeros.</p>
                          </div>
                        </div>
                    </div>
                  </div>
                  <br/>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-0 mt-0 uppercase"><i className="fa fa-ticket"></i> paso 3</h4>
                    </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8">
                            <p>Te mandamos tu recibo correspondiente y se te asigna un lugar en el autobús. * Los lugares se van asignando conforme se vayan apartando.*</p>
                          </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="card">
                  <div className="card-body">
                    <h4 className="mb-0 mt-0 uppercase"><i className="fa fa-usd"></i> paso 4</h4>
                  </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <p>Liquida tu viaje en la fecha indicada, envíanos tu ficha, recibe tu comprobante, itinerario y recomendaciones.</p>
                        </div>
                      </div>
                  </div>
                </div>
                <br/>
                <div className="card">
                  <div className="card-body">
                    <h4 className="mb-0 mt-0 uppercase"><i className="fa fa-suitcase"></i> paso 5</h4>
                  </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <p><i className="bold uppercase">Disfruta tu viaje.</i></p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Information;
