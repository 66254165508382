import React from 'react';
import $ from 'jquery';

function Terms(props) {

  (function init() {
    window.scrollTo(0, 0)
    $("header").addClass("lastHeader");
  })();

  return(
    <div>
    <br/><br/>
        <div className="container">
          <div className= "card card-body">
            <center><h2 className="bold">Términos y condiciones</h2></center>
            <br/>
              <h4 className="mb-0 mt-0 uppercase">Políticas de Reservación</h4>
                <br/>
                <ul className="list-yes">
                  <li>Las tarifas que aplican son las proporcionadas por Viaventuras.</li>
                  <li>Su recibo de confirmación le será enviado después de que se haya efectuado el pago, hasta ese momento se considera una reservación completa.</li>
                  <li>Las reservaciones de los servicios están sujetas a disponibilidad al momento de procesar su solicitud y no podrán ser garantizadas hasta haber recibido el pago total.</li>
                  <li>Posterior a la reservación y confirmación, le pedimos acudir con identificación oficial el dia del viaje.</li>
                  <li>Le sugerimos leer las políticas de cancelación ya que cualquier cambio de reservación está sujeto a disponibilidad y re-cotización de tarifas, recuerde tener a la mano su número de reservación y contactarnos</li>
                  <li>Los precios son por persona e incluyen los servicios que se indican en la descripción.</li>
                </ul>

              <br/><br/>
              <h4 className="mb-0 mt-0 uppercase">Políticas de Pago</h4>
                <br/>
                <ul className="list-yes">
                  <li>Aceptamos pagos a través de transferencia bancaria o depósito a tarjeta.</li>
                  <li>Por el momento no ofrecemos factura fiscal.</li>
                </ul>

              <br/><br/>
              <h4 className="mb-0 mt-0 uppercase">Políticas de Cancelación</h4>
              <br/>
              <ul className="list-yes">
                <li>Las cancelaciones deben ser enviadas por alguno de nuestros medios de contacto, correo viaventurasmx@gmail.com, Whatsapp o Facebook.</li>
              </ul>

              <br/><br/>
              <h4 className="mb-0 mt-0 uppercase">Para Transportación terrestre y/o tours</h4>
                <br/>
                <ul className="list-yes">
                  <li>Reservaciones canceladas 3 días o más antes de la fecha del servicio están sujetas a un cargo del 80% del monto total de la cantidad de su reservación.</li>
                  <li>Reservaciones canceladas 2 a 0 días antes de la fecha del servicio o en caso de no llegar, no serán reembolsables o endosables.</li>
                  <li>En los paquetes ofrecidos, Viaventuras se reserva el derecho de modificar el itinerario de viaje o de los hoteles previstos solo en caso de que fuera imprescindible para una mejor organización del mismo o si existen circunstancias de fuerza mayor que lo obliguen.</li>
                  <li>Viaventuras no será responsable de los retrasos, adelantos o cancelaciones en los itinerarios.</li>
                  <li>En el caso de los tours, en caso de no presentarse la cantidad mínima de viajeros previstos o por cualquier causa justificada, Viaventuras se verá obligado a suspender cualquiera de los tours previstos y otorgarlos en otra fecha o en caso de cancelar ese tour el importe será abonado al cliente en su próximo viaje.</li>
                  <li>Si se ha realizado una reserva de un paquete y no es utilizado el transporte por decisión del pasajero, no habrá ningún tipo de descuento en la tarifa y/o reembolso del costo del transporte.</li>
                </ul>

                <br/><br/>
                <h4 className="mb-0 mt-0 uppercase">Cancelación por causas no imputables a la empresa</h4>
                <br/>
                <ol>
                <ul className="list-yes">
                  <li>Viaventuras no asumirá responsabilidad por ninguna reclamación, costo o gastos que surjan por heridas personales o de terceras personas, accidentes o deceso, pérdida o daños de pertenencias personales, pérdida de diversión, enojo, desilusión, angustia o frustración ya sea mental o física que resulte de lo se menciona a continuación:
                      <ul className="sublist-yes">
                          <li>Actos u omisiones de cualquier otra persona que no sean empleados de Viaventuras.</li>
                          <li>Enfermedad, robo, disputas laborales, fallas mecánicas, cuarentena, acciones gubernamentales, clima, o cualquier otra causa ajena al control directo de Viaventuras.</li>
                          <li>Incumplimiento del cliente en obtener la documentación requerida para su viaje como: cupón de servicios, en ese caso usted no tendrá derecho a ningún reembolso.</li>
                          <li>Omisión de seguimiento de instrucciones por parte del cliente en horarios de salida, hora y fecha de entrada y salida de hoteles, política de canje de cupones.</li>
                          <li>Cancelación o cambio por cualquier motivo de los servicios de viaje ofrecidos. Nos reservamos el derecho de cancelar o cambiar los servicios de viaje a nuestra discreción, pero se tratará de sustituirlos con servicios comparables. En caso de cancelación Viaveturas tendrá la responsabilidad de reembolsar el monto total del dinero pagado.</li>
                          <li>Si los servicios son cancelados o interrumpidos por causas que están fuera de control de Viaventuras tales como clima, huracán, terremoto, actos de terrorismo, etc.</li>
                      </ul>
                  </li>
                  <li>Viaventuras mantiene actualizada la base de datos respecto a precios y condiciones de los servicios, no obstante, existe la posibilidad de que se realicen modificaciones o proporcionen información errónea respecto a la vigencia de algunas tarifas; si la nueva tarifa es menor a la cotizada, se aplicará el monto menor; en caso de ser mayor,
                  nos pondremos en contacto con usted para informarle esta modificación y que usted esté en libertad de aceptar el incremento en el precio o realizar la cancelación de la reservación debido a este incremento, no omitimos mencionar que Viaventuras se deslinda expresamente de cualquier responsabilidad o pago de indemnización por esa cancelación.</li>
                  <li>Las fotografías mostradas en el portal son representativas y no garantiza que a su llegada todo sea exactamente igual como aquí se puede ver.</li>
                  <li>La categoría de las estrellas asignada a los hoteles y servicios, están basados en la interpretación de Viaventuras y puede diferir de las categorías reportadas en otros lugares.</li>
                  <li>La descripción de los servicios de viaje son informativas y no garantizan que serán exactamente iguales a su llegada.</li>
                  <li>Viaventuras reserva el derecho de rechazar a cualquier cliente en cualquier momento.</li>
                  <li>Cualquier reclamación deberá hacerse por escrito a cualquiera de nuestros medio de contacto debiendo ser recibida a más tardar 15 días después del regreso de su viaje. Pasado ese lapso de tiempo la empresa no recibirá reclamo alguno.</li>
                </ul>
                </ol>

                <br/><br/>
                <h4 className="mb-0 mt-0 uppercase">Nuestros precios no incluyen</h4>
                  <br/>
                  <ul className="list-yes">
                    <li>Propinas de tipo personal a guías, conductores, botones, etc.</li>
                    <li>En paquetes: Traslados a sitios diferentes de los que se encuentran especificados.</li>
                    <li>Gastos extras de ningún tipo que no estén debidamente especificados en los itinerarios.</li>
                  </ul>
          </div>
        </div>
    </div>
  );
}

export default Terms;
