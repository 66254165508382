import React, { Component } from "react";
import SectionQuestions from './SectionQuestions';
import $ from 'jquery';

class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: []
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
    $("header").removeClass("lastHeader");


      setTimeout(() => {
        $("#faqs").toggleClass("none zoomIn animated");
      }, 1000); // simulates an async action, and hides the spinner


        this.getQuestions((questions) => {
          this.setState({
            questions: questions
          })
        });
  }

  getQuestions(callback) {
      this.props.get_collection("faqs")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback([]);
      });
      //callback([]);

  }


  render() {

      let questions = [];

      questions.push( this.state.questions
        .filter(test => test.data.category === 0)
      );

      questions.push( this.state.questions
        .filter(test => test.data.category === 1)
      );

      questions.push( this.state.questions
        .filter(test => test.data.category === 2)
      );

    return (
      <div>
          <div className="wallpaperFAQs">
            <div>
              <h3 id="faqs" className="none">Preguntas Frecuentes</h3>
            </div>
          </div>

          <div className="container faqs">
            <div className= "card card-body">
              <SectionQuestions
              title="Reservaciones"
              questionsList={questions[0]}
              />
              <SectionQuestions
              title="Pagos y Reembolsos"
              questionsList={questions[1]}
              />

              <SectionQuestions
              title="Cancelaciones"
              questionsList={questions[2]}
              />

        </div>
      </div>
  </div>
    );
  }
}
export default FAQs;
