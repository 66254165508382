import React, { Component } from "react";
import $ from 'jquery';

class Travels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: []
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0)
    $("header").removeClass("lastHeader");

      setTimeout(() => {
        $("#faqs").toggleClass("none zoomIn animated");
      }, 1000); // simulates an async action, and hides the spinner
  }

  render() {

    return (
      <div>
          <div className="wallpaperFAQs">
            <div>
              <h3 id="faqs" className="none">Viajes</h3>
            </div>
          </div>

          <div className="container faqs">
            <div className= "card card-body">
              <p>Para consultar nuestros viajes por favor visita nuestra página de Facebook: <a className="bold" href="https://www.facebook.com/viaventurasmx" target="_blank" rel="noopener noreferrer"> Viaventuras</a>
                  <br/><br/>
                  Debido a la contingencia, estamos publicando nuestros paquetes de acuerdo a las próximas fechas disponibles.
                <br/><br/>
                Anímate a vivir una experiencia con Viaventuras,  <i className="bold slogan"> porque viajar vale la pena</i>.

              </p>
        </div>
      </div>
  </div>
    );
  }
}
export default Travels;
