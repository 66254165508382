import React, { Component } from "react";
import { Table } from 'reactstrap';
import ItemTestimonial from './ItemTestimonial';

import Alert from '../../Alert';

class TestimonialsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: []
    };

    this.validateTestimonial = this.validateTestimonial.bind(this);
    this.getTestimonials = this.getTestimonials.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)

  this.getTestimonials((testimonials) => {
    this.setState({
      testimonials: testimonials
    })
  });
}


  getTestimonials(callback) {
      this.props.get_collection("testimonials")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }


  renderDelete (key, name) {
    Alert.warningAlert(" la opinión de " + name).then((willDelete) => {
      if(willDelete)
      {
          this.props.delete_doc("testimonials",key, name);
      } else {
        Alert.actionCanceled();
      }
    })
  }

  validateTestimonial (key, name) {
    this.setState({
      validateKey: key
    });

    Alert.confirmAction("¿Quiere validar este comentario?").then((value) => {
        if(value)
        {
            let testData =  {
                "valid": true
            };

            this.props.update_field("testimonials", key, testData, "Opinión Validada!");


        } else {
          Alert.actionCanceled();
        }
      })

  }

  render() {

    let testimonialsData;

    testimonialsData = this.state.testimonials
      .map(testimonial => {
        return (
          <ItemTestimonial
            id={testimonial.id}
            name={testimonial.data.name}
            rate={testimonial.data.rate}
            message={testimonial.data.testimonial}
            email={testimonial.data.email}
            valid={testimonial.data.valid}
            renderDelete ={this.renderDelete}
            validateTestimonial ={this.validateTestimonial}
          />
        );
      });

    return (
      <div className="container panelC">
            <h2>Sección de opiniones</h2>

            <div className="actions-space">

            </div>

              <Table hover>
              <thead className="thead">
                <tr>
                  <th>Nombre</th>
                  <th>Puntuación</th>
                  <th>Correo</th>
                  <th>Válido</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody>{
                this.state.testimonials.length > 0
                ? testimonialsData
                : <p>No hay ninguna opinión</p>
                }</tbody>
              </Table>


        </div>
    );
  }
}

export default TestimonialsPanel;
