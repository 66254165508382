import React, { Component } from "react";
import {NavLink} from 'react-router-dom';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1
    };
  }

  componentDidMount() {
  window.scrollTo(0, 0)
}

 handleInicio() {
   window.location.href = '/inicio';
 }


  render() {

    return (
          <div>

            <div className="fixpanel">
              <nav className="panelmenu">
                <ul>
                <li>
                <button className="bPannel" onClick={()=> this.handleInicio()}>Ver pagina</button>
                </li>
                  <li>
                  <button className="bPannel" onClick={()=> this.props.onLogout()}>
                  Salir
                  </button></li>
                </ul>
              </nav>
            </div>

            <nav className="panelnav">
              <div className="sectionpanel">
                <p>Viaventuras</p>
              </div>
                <ul className="panelul">
                  <li className="paneli">
                  <NavLink to={`/admin`} className="panelink">
                  Administrador
                  </NavLink>
                  </li>
                  <li className="paneli">
                  <NavLink to={`/viajes`} className="panelink">
                  Viajes
                  </NavLink></li>
                  <li className="paneli">
                  <NavLink to={`/ordenes`} className="panelink">
                  Órdenes
                  </NavLink></li>
                  <li className="paneli">
                  <NavLink to={`/viajeros`} className="panelink">
                  Viajeros
                  </NavLink></li>
                  <li className="paneli">
                  <NavLink to={`/opiniones`} className="panelink">
                  Opiniones
                  </NavLink></li>
                  <li className="paneli">
                  <NavLink to={`/preguntas`} className="panelink">
                  Preguntas frecuentes
                  </NavLink></li>
                </ul>
            </nav>
          </div>
    );
  }
}

export default Panel;
