import React, { Component } from 'react';
import Main from './Components/MainComponent';
import Panel from './Components/w-c-Pmin/MainPanel';
import firebase from 'firebase';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import "./scss/style.scss";

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      authed: false,
      panel: false,
      infoUser: null
    }

    this.handleAuth = this.handleAuth.bind(this);
    this.handlePanel = this.handlePanel.bind(this);
  }


  getInfo(callback) {
      //Send the user information to the backend server

  }



  componentWillMount() {
      firebase.auth().onAuthStateChanged(user => {
          this.setState({ user });
          if(user != null) {
            this.setState({authed: true});

          } else {
              this.setState({authed: false});
          }
      });
    }

  handleAuth(email, password) {
    firebase.auth().signInWithEmailAndPassword(email, password).then(function () {
        //window.location.href = '/w/ad/w-panel'; //relative to domain
        //window.location.assign('/w/ad/w-panel'); //relative to domain
        this.setState({panel: true});
    }.bind(this))
    .catch(function(error) {
      // Handle Errors here.
      console.error("Ha ocurrido un error: ", error.code, ": ", error.message);
      // ...
    });
  }


  handleLogOut() {
    firebase.auth().signOut().then(function () {
        window.location.href = '/inicio'; //relative to domain
    }).catch(function (error) {
      console.error("Ha ocurrido un error: ", error.code, ": ", error.message);
      // An error happened.
    });
  }

  handlePanel(handle) {
    this.setState({panel: handle});
  }


  render() {
    //{Object.keys(this.state.dataLang).length === 0

    return (
      <BrowserRouter>
        {
          this.state.authed === false ?
          <Main
            onAuth={this.handleAuth}
            onLogout={this.handleLogOut}
            authed = {this.state.authed}
            infoUser={this.state.infoUser}
          />
          : this.state.panel ?
          <Panel
            onLogout={this.handleLogOut}
          />
          : <Main
            onAuth={this.handleAuth}
            onLogout={this.handleLogOut}
            authed = {this.state.authed}
            infoUser={this.state.infoUser}
            handlePanel={this.handlePanel}
          />
      }
      </BrowserRouter>
    );
  }
}

export default App;
