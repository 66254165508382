import React, { Component } from "react";
import Alert from '../../Alert';

class ItemQuestion extends Component {

  showMessage(message) {
    Alert.infoAlert(message);
  }

  render() {
    let id = this.props.id;
    let title = this.props.title;
    let content = this.props.content;
    let category = this.props.category;

    return (
          <tr>
            <th scope="row" className="maxTh">{title}</th>
            <td>{category}</td>
            <td>
              <i className="options" onClick={() => this.showMessage(content)}><i className="pIcon far fa-comment"></i></i>
              <i className="options" onClick={() => this.props.renderDeleteQuestion(id, title)}><i className="pIcon delete fas fa-trash-alt"></i></i>

            </td>
          </tr>
    );
  }
}

export default ItemQuestion;
