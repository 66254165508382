import React, { Component } from "react";
import $ from 'jquery';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    };


  }

  componentDidMount() {
  window.scrollTo(0, 0)
  $("header").removeClass("lastHeader");

    setTimeout(() => {
      $("#contact").toggleClass("none zoomIn animated");
    }, 1000); // simulates an async action, and hides the spinner
}


  render() {
    return (
      <div>
          <div className="wallpaperContact">
            <div className="textContact">
              <h3 id="contact" className="none">Contáctanos</h3>
            </div>
          </div>

          <div className="container">
            <div className= "card card-body">
              <center><h2 className="bold">Estamos para ayudarte</h2></center>
              <br/>

          <section>
            <div className="row text-center">
              <div className="col-md-4">
                <div className="box-simple">
                  <div className="icon-outlined"><i class="fa fa-facebook"></i></div>
                  <h3 className="h4">Facebook</h3>
                  <p>Mándanos mentraje a nuestra página de Facebook: <a className="bold" href="https://www.facebook.com/viaventurasmx" target="_blank" rel="noopener noreferrer"> Viaventuras</a></p>
                  <br/>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box-simple">
                  <div className="icon-outlined"><i class="fa fa-phone"></i></div>
                  <h3 className="h4">Teléfonos</h3>
                  <p>Para contactarnos marque los siguientes números: <br/>
                    <a className="bold" href="tel:315-101-3740">31 51 01 37 40</a> - <a className="bold" href="tel:315-107-7903">31 51 07 79 03</a><br/>
                    También puede contactarnos directamente por <br/> <a className="bold" href="https://wa.me/5213151013740?text=Me%20gustar%C3%ADa%20consultar%20la%20disponibilidad%20de%20un%20viaje." target="_blank" rel="noopener noreferrer"><img  width="20" height="20" src="img/whatsapp.png" alt="Whatsapp +52315-101-3740"/> Whatsapp</a>.
                  </p>
                  <br/>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box-simple">
                  <div className="icon-outlined"><i class="fa fa-envelope"></i></div>
                  <h3 className="h4">Correo Electrónico</h3>
                  <p>Envíanos un correo electronico para ponernos en contacto contigo.</p>
                  <ul className="list-unstyled text-sm">
                    <li><strong><a href="mailto:contacto@viaventuras.com">contacto@viaventuras.com</a></strong></li>
                  </ul>
                  <br/>
                </div>
              </div>
            </div>
          </section>
            </div>
          </div>
      </div>
    );
  }
}
export default Contact;
