import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {


  function renderUser() {
    return (
      <div>
          <h5 className="bold">Admin</h5>
          <ul className="list-unstyled">
            <button className="btn-success" onClick={()=> props.handlePanel(true)}>Administrar</button>
            <br/><br/>
            <button onClick={()=> props.onLogout()}>Salir</button>
        </ul>
      </div>
    );
  }

  function render() {
      return (
        <div>
            <h5 className="bold">¿Tienes preguntas?</h5>
            <ul className="list-unstyled">
              <li><Link to={`/contacto`}>Contáctanos</Link></li>
              <li><Link to={`/ingresar`}>Ingresar</Link></li>
            </ul>
        </div>

      )
  }

  return(
        <div className="footer">
          <div className="container">

              <div className="row justify-content-center">

              <div className="col-6 col-sm-3 info-section">
                  <h5 className="bold">Viaventuras</h5>
                  <ul className="list-unstyled">
                      <li className="bold slogan">Porque viajar vale la pena</li><br/>
                      <li>Agencia de Viajes virtual que busca crear experiencias y conexión con todos sus clientes.</li>
                  </ul>
                  <br/>
              </div>

              <div className="col-6 col-sm-3 links-section">
                  <h5 className="bold">Informacion</h5>
                  <ul className="list-unstyled">
                      <li><Link to={`/inicio`}>Inicio</Link></li>
                      <li><Link to={`/nosotros`}>Nosotros</Link></li>
                      <li><Link to={`/viajes`}>Viajes</Link></li>
                      <li><Link to={`/opiniones`}>Opiniones</Link></li>
                      <li><Link to={`/terminos-y-condiciones`}>Términos y Condiciones</Link></li>
                  </ul>
              </div>

              <div className="col-6 col-sm-3 info-section">
                  <h5 className="bold">Soporte al cliente</h5>
                  <ul className="list-unstyled">
                      <li><Link to={`/preguntas`}>Preguntas Frecuentes</Link></li>
                      <li><Link to={`/informacion`}>¿Cómo Reservar?</Link></li>
                      <li><Link to={`/contacto`}>Contáctanos</Link></li>
                  </ul>
              </div>

              <div className="col-6 col-sm-3 social-section">
                  {props.authed ? renderUser() : render()}
              </div>

            </div>
            <div className="row justify-content-center mt-4">
                <div className="col-auto">
                    <p>Copyright ©2020 | Viaventuras</p>
                    <br/>
                </div>

            </div>
          </div>
        </div>
  );
}

export default Footer;
