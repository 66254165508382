import React, { Component } from "react";

class ItemUser extends Component {

  render() {
    let id = this.props.id;
    let userid = this.props.userid;
    let name = this.props.name;
    let birthday = this.props.birthday;
    let first_travel = this.props.first_travel;
    let travels_counter = this.props.travels_counter;

    return (
          <tr>
            <th scope="row" className="maxTh">{userid}</th>
            <td>{name}</td>
            <td>{birthday}</td>
            <td>{first_travel}</td>
            <td>{travels_counter}</td>
            <td>
              <i className="options" onClick={() => this.props.changeOption(3, id)}><i className="pIcon fas fa-edit"></i></i>
              <i className="options" onClick={() => this.props.renderDelete(id, name)}><i className="pIcon delete fas fa-trash-alt"></i></i>

            </td>
          </tr>
    );
  }
}

export default ItemUser;
