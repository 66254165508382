import React, { Component } from "react";
import TestimonialComponent from './TestimonialComponent';
import { Collapse, CardBody, Card, Col, Row, Button , Form, FormGroup, Label, Input, Spinner} from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';
import $ from 'jquery';


class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [],
      collapse: false,
      rating: 1,
      isValid: false,
      loading: false
    };

    this.handleTestimonial = this.handleTestimonial.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    $("header").removeClass("lastHeader");

      setTimeout(() => {
        $("#testimonials").toggleClass("none zoomIn animated");
      }, 1000); // simulates an async action, and hides the spinner


      this.getTestimonials((testimonials) => {
        this.setState({
          testimonials: testimonials
        })
      });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  getTestimonials(callback) {

      this.props.get_collection("testimonials")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback([]);
      });
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({rating: nextValue});
  }


  validateData(callback){
      let nameBool = false;
      let emailBool = false;
      let messageBool = false;

      let name = $("#name").val();
      let email = $("#email").val();
      let message = $("#message").val();
      let rate = this.state.rating;

       if(name !== "" && name.length > 3) {
          nameBool = true;
       }

      if(message !== "") {
         messageBool = true;
      }

       if(email !== "") {
         var re = /\S+@\S+\.\S+/;
         if (re.test(email)) {
           emailBool = true;
         }
       }

       //Verification of everything
      if(nameBool && emailBool && messageBool){

          let data =  {
              "name": name,
              "email": email,
              "testimonial": message,
              "rate": rate,
              "valid": false
          };

          this.setState({isValid: true}, ()=> callback(data));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

    handleTestimonial (e) {
      this.toggleLoading();

      this.validateData((data)=>{
          if (this.state.isValid) {
              this.props.set_fieldDefault("testimonials",data,"Opinión");
            }

        });

        e.preventDefault(); //Don't execute

      }


  render() {
      const { rating } = this.state;

      let testimonials = [];

      testimonials.push( this.state.testimonials
        .filter(test => test.data.valid === true)
      );

      let testimonialsValid;

      testimonials[0].length > 0
      ? testimonialsValid = testimonials[0]
        .map(test => {
          return (
            <TestimonialComponent
              key={test.id}
              name={test.data.name}
              testimonial={test.data.testimonial}
              rate={test.data.rate}
            />
          );
        })
      : testimonialsValid = [];

    return (
      <div>
          <div className="wallpaperTestimonial">
            <div>
              <h3 id="testimonials" className="none">Opiniones</h3>
            </div>
          </div>

          <div className="container testimonials">
            <div className= "card card-body">

          {testimonialsValid}

        <button onClick={this.toggle}>Agregar opinión</button>

        <br/>
        <Collapse isOpen={this.state.collapse}>
            <Card>
              <CardBody>
                <Form>
                  <Row form>
                  <Col md={6}>
                      <FormGroup>
                        <Label for="name">Nombre</Label>
                        <Input type="text" name="name" id="name" required />
                      </FormGroup>
                  </Col>
                  </Row>

                  <FormGroup>
                    <h5>Seleccione puntuación</h5>
                    <StarRatingComponent className="rating"
                      name="rate1"
                      starCount={5}
                      value={rating}
                      onStarClick={this.onStarClick.bind(this)}
                    />
                  </FormGroup>


                  <Row form>
                  <Col md={6}>
                    <FormGroup>
                        <Label for="email">Correo electrónico</Label>
                        <Input type="email" name="email" id="email" required />
                    </FormGroup>
                  </Col>
                  </Row>

                  <Row form>
                  <Col md={6}>
                      <FormGroup>
                      <Label for="message">¡Escríbenos tu opinión!</Label>
                        <Input type="textarea" name="message" id="message" required />
                      </FormGroup>
                  </Col>
                  </Row>

                <button className="btn-testimonial" onClick={(e) => this.handleTestimonial(e)}>Enviar</button>

                <Button className="btn-testimonial" onClick={this.toggle}> Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
              </Form>

            </CardBody>
          </Card>
        </Collapse>

        </div>
      </div>
  </div>
    );
  }
}
export default Testimonials;
