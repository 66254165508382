import React, { Component } from "react";

class ItemTravel extends Component {

  render() {
    let id = this.props.id;
    let travelid = this.props.travelid;
    let destination = this.props.destination;
    let date = this.props.date;
    let cost = this.props.cost;
    let capacity = this.props.capacity;
    let occupied = this.props.occupied;

    let free = capacity - occupied;

    return (
          <tr>
            <td>{travelid}</td>
            <th scope="row" className="maxTh">{destination}</th>
            <td>{date}</td>
            <td>$ {cost}</td>
            <td>{capacity}</td>
            <td>{free}</td>
            <td>
              <i className="options" onClick={() => this.props.changeOption(3, id)}><i className="pIcon fas fa-edit"></i></i>
              <i className="options" onClick={() => this.props.renderDelete(id, destination)}><i className="pIcon delete fas fa-trash-alt"></i></i>

            </td>
          </tr>
    );
  }
}

export default ItemTravel;
