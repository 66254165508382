import React, { Component } from "react";
import { Button, Table } from 'reactstrap';
import ItemUser from './ItemUser';
import AddUser from './AddUser';
import EditUser from './EditUser';

import Alert from '../../Alert';

class UsersPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 1,
      users: [],
      selectedUser: null,
    };

    this.getUsers = this.getUsers.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
  }

  componentDidMount() {
  window.scrollTo(0, 0)

    this.getUsers((users) => {
      this.setState({
        users: users
      })
    });
}

  getUsers(callback) {
      this.props.get_collection("users")
      .then(function(data) {
        callback(data);
      })
      .catch(function () {
        callback();
      });
  }

  renderDelete (key, name) {
    Alert.warningAlert(name).then((willDelete) => {
      if(willDelete)
      {
          this.props.delete_doc("users",key, name);
      } else {
        Alert.actionCanceled();
      }
    })
  }

  changeOption(option, id) {
      this.setState({
        option: option,
        selectedUser: id
      })
  }


  renderOptions()  {

    switch (this.state.option) {
      case 1:

          let users;

          this.state.users.length > 0 ?
            users = this.state.users
              .map(user => {
                return (
                  <ItemUser
                    key={user.id}
                    id={user.id}
                    userid={user.data.userid}
                    name={user.data.name}
                    birthday={user.data.birthday}
                    phone={user.data.phone}
                    first_travel={user.data.first_travel}
                    travels_counter={user.data.travels_counter}
                    changeOption={this.changeOption}
                    renderDelete ={this.renderDelete}
                  />
                );
              })
          : users = <p>No hay ningún usuario</p>;

        return (
            <div>

              <Table hover>
              <thead className="thead">
                <tr>
                  <th>Folio</th>
                  <th>Nombre</th>
                  <th>Cumpleaños</th>
                  <th>Primer Viaje</th>
                  <th>Viajes</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody>{users}</tbody>

              </Table>
            </div>
        )
        break;

      case 2:
        return (
          <div>
          <AddUser
          changeOption={this.changeOption}
          set_fieldDefault = {this.props.set_fieldDefault}
          />
          </div>
        )
        break;

      case 3:
        return (
          <div>
          <EditUser
          userid = {this.state.selectedUser}
          changeOption={this.changeOption}
          get_item = {this.props.get_item}
          update_field = {this.props.update_field}
          />
          </div>
        )
        break;

      default:
        return (
          <div>
            Ninguna opción fue seleccionada.
          </div>
        )
        break;
    }

  }


  render() {
    let textButton = this.state.option === 1 ? "Crear" : "Regresar";
    let textTitle = this.state.option === 1 ? "Viaventureros" : this.state.option === 2 ? "Nuevo usuario" : "Modificar usuario" ;

    return (
      <div className="container panelC">
            <h2>{textTitle}</h2>

            <div className="actions-space buttons-panel" >
              {  this.state.option === 1 ?
                <section>
                <Button color="success" onClick={() => {this.changeOption(2,null)}}>{textButton}</Button>
                </section>
                : <Button color="success" onClick={() => {this.changeOption(1,null)}}>{textButton}</Button>
              }
            </div>

            {this.renderOptions()}

        </div>
    );
  }
}

export default UsersPanel;
