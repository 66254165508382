import React, { Component } from "react";
import { Collapse, Col, Row, Button , Form, FormGroup, Label, Input, Spinner,FormText } from 'reactstrap';
import $ from 'jquery';
import Alert from '../../Alert';

class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        users: 1,
        isMinor: false,
        addUsers: false,
        travelsList: null,
        usersList: null,
        selectedTravel: null
    };

    this.handleData = this.handleData.bind(this);
    this.validateData = this.validateData.bind(this);
    this.toggleMinor = this.toggleMinor.bind(this);
    this.toggleUsers = this.toggleUsers.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    // Click and hide menumobile .lnk-menu
    let core = this;
    $("#travelid").change(function() {
        var value = $(this).val();
        let list = core.state.travelsList;
        let travel = list.find(element => element.data.travelid === value);
        core.setState({selectedTravel: travel})
    });

    $("#seats").change(function() {
        var value = $(this).val();

        if (value > 0)
        {
          if (core.state.selectedTravel)
          {
            let cost = value * core.state.selectedTravel.data.cost;
            $("#total").val(`$ ${cost}`)

            $("#showFields").removeClass("showFields");
          }

        } else {
          $("#showFields").addClass("showFields");
        }
    });

    $("#minor").change(function() {
        var minor = $(this).val();

        let adults = $("#seats").val();

        if (parseInt(minor) >= adults)
        {
          $(this).addClass("errorField")
        }else {
          $(this).removeClass("errorField")

          if (core.state.selectedTravel)
          {
            let value = core.state.selectedTravel.data.cost / 2;

            let cost = minor * Math.round(value);

            $("#discountMinor").val(`$ ${cost}`)
          }

        }
    });


    this.props.getCollection((data) => {

      data.map(item => {
        $('#travelid').append(`<option value="${item.data.travelid}"> ${item.data.travelid} </option>`);
      })

        this.setState({
          travelsList: data
        });

    }, "travels");

    this.props.getCollection((data) => {

      data.map(item => {
        $('#userPrincipal').append(`<option value="${item.data.userid}"> ${item.data.userid} | ${item.data.name}  </option>`);
      })

        this.setState({
          usersList: data
        });

    }, "users");

}

  renderTravel() {
      $("#destination").val(this.state.selectedTravel.data.destination);
      $("#cost").val(`$ ${this.state.selectedTravel.data.cost}`);
  }

  validateData(callback) {
      let validateBool = false;

      //Values saved
      let userPrincipal = $('#userPrincipal').val();
      let travelid = $('#travelid').val();
      let seats = $('#seats').val();
      let paid = $('#paid').val();


      // optinals
      let phone = $("#phone").val();
      let minor = $("#minor").val();


      if(userPrincipal !== "" && travelid !== "" && seats !== "" && paid !== "") {
        if (seats > 0)
        {
          validateBool = true;
        }
      }

       //Verification of everything
      if(validateBool){

        seats = parseInt(seats);
        paid = parseInt(paid);

        minor = minor === "" ? 0 : parseInt(minor);

        let adult = seats - minor;

        let free_nine = seats > 8 ? true : false;

        let subtotal = seats * this.state.selectedTravel.data.cost;
        
        let disc_user = 0;
        let disc_minor = 0;

        if (free_nine)
        {
           disc_user = this.state.selectedTravel.data.cost;
        }

        if (minor > 0)
        {
          let valueMinor = this.state.selectedTravel.data.cost / 2;

          disc_minor = minor * Math.round(valueMinor);
        }

        let discount = parseInt(disc_user) + parseInt(disc_minor);
        let total = subtotal - discount;
        // get userids

        let aditionalUsers = $(".aditionalUser");

        let userslistid = [];

        if (aditionalUsers.length > 0)
        {
          aditionalUsers.map((userid, user) => {
            let val = $(user).val();
            if(val !== "")
            {
              userslistid.push(val);
            }
          })
        }

          let newTravel =  {
              "userPrincipal": userPrincipal,
              "travelid": travelid,
              "destination": this.state.selectedTravel.data.destination,
              "seats": seats,
              "paid": paid,
              "phone": phone.length > 5 ? phone : "",
              "minor": minor,
              "adult": adult,
              "free_nine": free_nine,
              "completed": false,
              "information": this.state.selectedTravel.data.information,
              "subtotal": subtotal,
              "discount": discount,
              "total": total,
              "userslistid": userslistid

          };

        this.setState({isValid: true}, ()=> callback(newTravel));
       }
      else{
          this.setState({isValid: false}, ()=> callback(null));
      }
   }

  handleData (e) {
      this.toggleLoading();
      this.validateData((data)=>{
          if (this.state.isValid) {
                this.props.set_fieldDefault("orders",data,"La orden se ha ");

          } else {
              Alert.errorAlert("¡Falta informacion! Revise y vuelta a intentar");
              this.toggleLoading();
            }
      });

      e.preventDefault(); //Don't execute
    }

  renderChangeOption() {
      this.props.changeOption(1, null);
    }

  toggleLoading() {
    this.setState({loading: !this.state.loading});
  }

  renderLoading() {
    return <Spinner color="primary"/>
  }

  toggleMinor() {
    this.setState({ isMinor: !this.state.isMinor });
  }

  toggleUsers() {
    this.setState({ addUsers: !this.state.addUsers });
  }

  renderUsersForm() {

    let users = $('#seats').val();

    if(users === "" )
    {
      return (
        <div>
        </div>
      )
    } else {

      let minor = $('#minor').val();

      minor = minor === "" ? 0 : minor;

      let adults = (users - minor) - 1;

      let forms = []

      for (var i = 1; i <= adults; i++) {
         let data = {
           key: "userN"+i
         }
         forms.push(data);
      }

      let formItem;
      formItem = forms
        .map(test => {
          return (
            <Col md={6} key={test.key}>
              <FormGroup>
                <Input className="aditionalUser" type="select" id={test.key}>
                  <option value=""></option>
                </Input>
              </FormGroup>
            </Col>
          );
        })

        return(
        <Row form>
              {formItem}
        </Row>
        )
      }
  }

  renderUsersSelect() {

    let usersList = this.state.usersList ? this.state.usersList : [];

    setTimeout(function() {

          let users = $('#seats').val();

          if(users !== "" )
          {
            let minor = $('#minor').val();
            minor = minor === "" ? 0 : minor;
            let adults = (users - minor) - 1;

            let ids = []

            for (var i = 1; i <= adults; i++) {
               let data = "userN"+i;
               ids.push(data);
            }

            ids.map(id => {

              $(`#${id}`).empty().append('<option value=""></option>');
                  usersList.map(item => {
                    $(`#${id}`).append(`<option value="${item.data.userid}"> ${item.data.userid} | ${item.data.name}  </option>`);
                  })
              })
          }
    }, 1000);

  }

  renderMinorField() {
    $("#minor").val("")
    $("#minor").removeClass("errorField")
    $("#discountMinor").val("")
  }

  render() {
    return (
      <div className="">
        <Form>
          <Row form>
          <Col md={6}>
              <FormGroup>
                <Label for="travelid">Seleccione el Viaje *</Label>
                <Input type="select" name="travelid" id="travelid" required>
                  <option value=""></option>
                </Input>
              </FormGroup>
          </Col>

          <Col md={6}>
              <FormGroup>
              <Label for="destination">Destino *</Label>
                <Input type="text" name="destination" id="destination" readOnly />
              </FormGroup>
          </Col>
          </Row>


          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Teléfono</Label>
                <Input type="text" name="phone" id="phone"/>
              </FormGroup>
            </Col>

            <Col md={6}>
                <FormGroup>
                <Label for="seats">Asientos *</Label>
                  <Input type="number" name="seats" id="seats" required />
                </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="cost">Costo por asiento</Label>
                <Input type="text" name="cost" id="cost" readOnly/>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="total">Costo total</Label>
                <Input type="text" name="total" id="total" readOnly/>
              </FormGroup>
            </Col>

            <Col md={3}>
                <FormGroup>
                <Label for="paid">Pago *</Label>
                  <Input type="number" name="paid" id="paid" />
                </FormGroup>
            </Col>
          </Row>

          <section id="showFields" className="showFields">
            <Row form>
              <Col md={4}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onClick={this.toggleMinor}/>{' '}
                    Hay menores de edad
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <br/>
            <Collapse isOpen={this.state.isMinor}>
              <Row form>
                <Col md={3}>
                    <FormGroup>
                    <Label for="minor">Cantidad de Menores</Label>
                      <Input type="number" name="minor" id="minor"/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="discountMinor">Descuento aplicable 50%</Label>
                    <Input type="text" name="discountMinor" id="discountMinor" readOnly/>
                  </FormGroup>
                </Col>
              </Row>
            </Collapse>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="userPrincipal">Seleccione viaventurero *</Label>
                  <Input type="select" name="userPrincipal" id="userPrincipal" required>
                    <option value=""></option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onClick={this.toggleUsers} />{' '}
                    Seleccionar viajeros restantes (mayores de edad)
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br/>
            <Collapse isOpen={this.state.addUsers}>
              {this.renderUsersForm()}
            </Collapse>

            <Row form>
              <Col md={8}>
                <FormGroup>
                    <FormText color="muted">
                    * El costo final con descuentos aplicados será generado una vez la orden sea creada.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <br/>

          </section>

            <div className=" buttons-panel">
              <button onClick={(e) => this.handleData(e)}>Crear orden</button>
              <Button onClick={() => this.renderChangeOption()}>Cancelar</Button> {this.state.loading ? this.renderLoading() : null}
            </div>
          </Form>

          {this.state.selectedTravel ? this.renderTravel() : null}
          {this.state.addUsers ? this.renderUsersSelect() : null}
          {this.state.isMinor ? null : this.renderMinorField()}

      </div>
    );
  }
}

export default AddOrder;
