import React, { Component } from 'react';
import firebase from 'firebase';
import {Switch, Route, Redirect} from 'react-router-dom';

import Home from './Home';
// HEADER, SIGNIN, FOOTER
import Header from './Generals/Header';
import Footer from './Generals/Footer';
import Signin from './Generals/Signin';
import About from './Generals/About';
import Information from './Generals/Information';
import Contact from './Generals/Contact';
import Terms from './Generals/Terms';
import Travels from './Generals/Travels';

// App Components
import Testimonials from './Testimonials/Testimonials';
import FAQs from './Questions/FAQs';
import Alert from './Alert';
import Panel from './w-c-Pmin/Panel';

var database;


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: true
    };

  }

  componentDidMount() {
  window.scrollTo(0, 0);

}

 componentWillMount() {
   database = firebase.firestore();
 }

  get_collection (collection) {
    var db_ref = database.collection(collection);
      return db_ref.get()
      .then(function (snapshot) {
        let data = [];
          snapshot.forEach((doc) => {
            data.push({
              id: doc.id,
              data: doc.data()
            });
          });
          return data;
        })
      .catch(function(error) {
        return null
      });
  }


  set_fieldDefault (collection, data, type) {
      var setDoc = database.collection(collection);
      return setDoc.add(data)
      .then(function() {
          Alert.successAlert(type +" enviada!").then((value) => {
            window.location.reload();
          });
      })
      .catch(function(error) {
          Alert.errorAlert("Algo salió mal, por favor verifica que tienes conexión a internet y vuelve a intentarlo!").then((value) => {
          });
      });
  }

  ProtectedRoute ({ component: Component, authed, ...rest }) {
    return (
      <Route {...rest}
        render={(props) => authed
          ? <Component authed={authed} {...props} />
          : <Redirect to={{pathname: '/'}} />}
      />
    );
  }

    UserAuthed (flag) {
      if (flag && this.state.home) {
        this.setState({home: false});
        return <Redirect to="/inicio"/>
      } else {
        return (
            <Route exact path="/" component={({children}) =>
                <Home onAuth={this.props.onAuth}>{children}</Home>
              } />
        );
      }

      /*



      <Route exact path="/nosotros" render={(props) => <About language = {this.props.language} {...props} /> } />
      <Route exact path="/viajes" render={(props) => <Articles dataLang={this.props.dataLang} language = {this.props.language} {...props} /> } />
      <Route exact path="/contacto"  render={(props) => <Contact dataLang={this.props.dataLang} {...props} /> } />
      <Route exact path="/opiniones" render={(props) => <Testimonials dataLang={this.props.dataLang} {...props} /> } />



      */
    }

    render() {
       return (
         <div>
             <Header/>
             <Switch>
                 <Route exact path="/" render={(props) =>
                   <Home
                     infoUser = {this.props.infoUser}
                     {...props} />
                 } />
                 <Route exact path="/inicio" render={(props) =>
                   <Home
                     infoUser = {this.props.infoUser}
                     {...props} />
                 } />
                 <Route exact path="/nosotros" render={(props) => <About {...props} /> } />
                 <Route exact path="/viajes" render={(props) => <Travels {...props} /> } />
                 <Route exact path="/contacto"  render={(props) => <Contact {...props} /> } />
                 <Route exact path="/informacion" render={(props) => <Information {...props} /> } />
                 <Route exact path="/opiniones" render={(props) => <Testimonials get_collection = {this.get_collection} set_fieldDefault={this.set_fieldDefault} {...props} /> } />
                 <Route exact path="/preguntas" render={(props) => <FAQs get_collection = {this.get_collection} {...props} /> } />
                 <Route exact path="/terminos-y-condiciones" render={(props) => <Terms {...props} /> } />
                 <Route exact path="/ingresar" render={(props) => <Signin onAuth={this.props.onAuth} {...props} /> } />
                 <this.ProtectedRoute path="/admin" component={Panel} authed={this.props.authed}/>
                 {this.props.authed ? this.UserAuthed(true) : this.UserAuthed(false)}
                 <Redirect to="/"/>
             </Switch>

             <Footer
               authed={this.props.authed}
               onLogout={this.props.onLogout}
               handlePanel={this.props.handlePanel}
               />
         </div>
       )
    }
}

export default Main;
